@import 'references/index';

.root-app-wrapper.lander-page {
    background-color: $primary-dark;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(180.54deg, #4ea635 -94.87%, #2b5c1d 17.8%, #000000 139.59%);
}

.root-app-wrapper.light-green-bg {
    background-color: #fbfcfa;
}

.root-app-wrapper.dark-green-bg {
    background-color: $primary-dark;
}

.root-app-wrapper {
    .rds-theme-bold-text {
        color: $primary-main;
        font-weight: 700;
    }
}

@media screen and (max-width: 992px) {
    .root-app-wrapper.lander-page {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-mobile.png?alt=media&token=01d45977-d57e-4d07-b78a-3f9e606f9f8a');
    }

    .root-app-wrapper.billion-cash-bg {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillion-dollar-money-mobile.png?alt=media&token=559a145a-b338-4298-b830-1562f2065a5c');
        background-repeat: repeat-x;
    }

    .root-app-wrapperv3.lander-page {
        background-color: unset;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillie-dollar-money-mobile.png?alt=media&token=1da24c2a-2c4c-40e2-b9a0-338f3974b70b');
        background-position: top;
    }

    .root-app-wrapperv2.lander-page {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-green-dollar-mobile.png?alt=media&token=c1e777ea-4342-4126-8f27-998b66501fe2');
        background-position: top;
    }
}

@media screen and (min-width: 992px) {
    .root-app-wrapper.lander-page {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-desktop.png?alt=media&token=39fb27a4-62e8-41ee-90ba-9e9a295d18a9');
    }
    .root-app-wrapperv2.lander-page {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillie-dollar-money-desktopv2.png?alt=media&token=4b57ab0c-f814-49ef-bfef-ba288a4f3aad');
        background-position: top;
    }
    .root-app-wrapperv3.lander-page {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillion-dollar-desktopv3.png?alt=media&token=e7719b0a-9aca-4464-b40a-fcb5487bc469');
        background-position: top;
        background-color: unset;
    }
}
